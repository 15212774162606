<template>
    <v-app>
        <v-card width="500" outlined class="mx-auto my-auto" :loading="loading">
            <v-container class="my-auto">

                <v-row>
                    <v-card-title>
                        <v-icon large left>mdi-sigma</v-icon>
                        <span class="title font-weight-light">{{title}}</span>
                    </v-card-title>
                </v-row>

                <v-row><v-col lg="12" md="12" xs="12" cols="12" align="center"></v-col></v-row>

                <v-row>
                    <v-tabs grow>
                        <v-tab v-on:click="changeLogin(1)">Email</v-tab>
                        <v-tab v-on:click="changeLogin(2)">Mobile</v-tab>
                    </v-tabs>
                </v-row>

                <v-row><v-col lg="12" md="12" xs="12" cols="12" align="center"></v-col></v-row>

                <template v-if="showLoginEmail">
                    <v-row>
                        <v-col lg="12" md="12" xs="12" cols="12" class="pl-0 pr-0">
                            <v-form ref="loginForm" v-model="infoValid" autocomplete="chrome-off">
                                <v-col lg="12" md="12" xs="12" cols="12">
                                    <v-text-field label="email" solo flat prepend-inner-icon="mdi-email" type="email" v-model="email" outlined dense />
                                    <v-text-field :type="!showPassword ? 'password' : 'text'" v-model="password" outlined dense
                                        label="Password" solo flat prepend-inner-icon="mdi-lock"
                                        :append-icon="showPassword ? 'mdi-eye': 'mdi-eye-off'"
                                        @click:append="showPassword = !showPassword" />
                                </v-col>
                            </v-form>
                        </v-col>
                    </v-row>

                    <v-row justify="center"></v-row>

                    <v-row justify="center" v-if="loginStatus == 0 || loginStatus == 1">
                        <v-col lg="12" md="12" xs="12" cols="12">
                            <v-alert type="success" dense v-if="loginStatus == 1">
                                {{ loginMsg }}
                            </v-alert>
                            <v-alert type="error" color="red" dense v-if="loginStatus == 0">
                                {{ loginMsg }}
                            </v-alert>
                        </v-col>
                    </v-row>

                    <v-row justify="center">
                        <v-col lg="12" md="12" xs="12" cols="12" align="center">
                            <v-btn block color="info" :loading="btnLoading"  @click="login" :disabled="!infoValid">Login</v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-card-text class="mb-5 pb-0">
                            <p class="text-center font-weight grey--text overline mb-0">
                                <a @click="redirectForgetPassword">Forget Password?</a>
                            </p>
                        </v-card-text>
                    </v-row>
                </template>

                <template v-if="showLoginMobile">

                    <v-row justify="center"><v-col lg="12" md="12" xs="12" cols="12"></v-col></v-row>

                    <v-row>
                        <v-col lg="12" md="12" xs="12" cols="12">
                            <div class="d-flex">
                                <v-text-field :type="'text'" v-model="mobile" dense prefix="61" outlined label="Mobile" flat solo  prepend-inner-icon="mdi-phone"/>
                                <v-btn dark color="default" :disabled="btnSendMessageDisable" text elevation="0"
                                    class="height: 40px !important;" v-on:click="sendCode()">
                                    {{ btnSendMessage }}
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col lg="12" md="12" xs="12" cols="12">
                            <v-text-field :type="'text'" v-model="code" dense outlined label="Code" solo flat  prepend-inner-icon="mdi-lock"/>
                            <v-btn block color="info" :disabled="btnCodeValidateDisable"
                                :loading="btnLoadingCodeValidate"  v-on:click="messageLogin()">Login</v-btn>
                        </v-col>
                    </v-row>
                    <!-- </v-row>
                        <v-col lg="12" md="12" xs="12" cols="12" align="center" class="pl-1 pr-1">
                            <v-btn block color="info" :loading="btnLoadingCode" large v-on:click="sendCode()">Send Code</v-btn>
                        </v-col>
                        <v-col lg="12" md="12" xs="12" cols="12" align="center" class="pl-1 pr-1">
                            <v-btn block color="info" :disabled="btnCodeValidateDisable"
                                :loading="btnLoadingCodeValidate" large v-on:click="messageLogin()">Login</v-btn>
                        </v-col>
                    </v-row> -->
                </template>

            </v-container>
        </v-card>
    </v-app>
</template>

<script>
import * as apiAuth from "@components/api/authpk/auth";
import * as apiAuthMessage from "@components/api/authpk/auth-message";
import { mapState } from "vuex";
import { snackbar } from "@components/mixins/snackbar";
// import { helper } from "@components/mixins/helper";
import { alert } from "@components/mixins/alert";

export default {
    mixins: [snackbar, alert],
    data: () => ({
        infoValid: true,
        title: "Sigma Position Keeper",
        email: "",
        password: "",
        redirectUrl: "",
        showPassword: false,
        btnLoading: false,
        loading: false,
        showLoginEmail: true,
        showLoginMobile: false,
        btnLoadingCode: false,
        btnLoadingCodeValidate: false,
        btnCodeValidateDisable: true,
        code: "",
        mobile: "",
        btnSendMessage: "Send Code",
        btnSendMessageDisable: false,
        timeCount: 60,
        intervalCount: null
    }),
    computed: {
        ...mapState([
            "loginStatus",
            "loginMsg",
            "loginBtnLoading"
        ])
    },
    watch: {
        /**
         * toggle button status according to form input
         * @param   {[type]}  nv  [nv description]
         * @param   {[type]}  ov  [ov description]
         * @return  {[type]}      [return description]
         */
        code (nv, ov) {
            if (nv === "") {
                this.btnCodeValidateDisable = true;
            } else {
                this.btnCodeValidateDisable = false;
            }
        },
        /**
         * watch timer change once to 1 reset
         * @param   {[type]}  nv  [nv description]
         * @param   {[type]}  ov  [ov description]
         * @return  {[type]}      [return description]
         */
        timeCount (nv, ov) {
            if (nv === -1) {
                // this.intervalCount = null;
                clearInterval(this.intervalCount);
                this.timeCount = 60;
                this.btnSendMessage = "Send Code";
                this.btnSendMessageDisable = false;
            }
        }
    },
    methods: {
        /**
         * Counter management for send code button
         * code is allowed to be resent every 1 minutes
         * @return  {[type]}  [return description]
         */
        startCountDown () {
            this.btnSendMessageDisable = true;
            this.intervalCount = setInterval(() => {
                this.btnSendMessage = this.timeCount.toString() + "s resend";
                this.timeCount -= 1;
            }, 1000);
        },
        /**
         * Action to call api to send auth code to mobile
         * @return  {[type]}  [return description]
         */
        sendCode () {
            const self = this;

            if (self.mobile === "" || self.mobile === undefined || self.mobile === null) {
                self.snackBarDanger("Please fill in your mobile");
                return;
            }
            const mobile = "61" + self.mobile;
            self.startCountDown();

            const param = { mobile: mobile };
            apiAuthMessage.fetch(param).then(res => {

            });
        },
        /**
         * Send code and mobile to login
         * @return  {[type]}  [return description]
         */
        messageLogin () {
            const self = this;

            if (self.mobile === "" || self.mobile === undefined || self.mobile === null) {
                self.snackBarDanger("Mobile cannot be empty");
                return;
            }

            if (self.code === "" || self.code === undefined || self.code === null) {
                self.snackBarDanger("Code cannot be empty");
                return;
            }

            const mobileNumber = "61" + self.mobile;
            const params = { mobile: mobileNumber, code: self.code };

            console.log(params);

            self.btnLoadingCodeValidate = true;
            self.loading = true;

            apiAuthMessage.create(params).then(res => {
                const data = res.data;
                console.log(data);

                self.snackBarSuccess(res.data.message);

                self.$store.state.jwtToken = data.access_token;
                self.$store.state.assignUser = data.user;
                // console.log(data.user.permission.frontPermission);

                localStorage.setItem("displayName", data.user.first_name + " " + data.user.last_name);
                localStorage.setItem("bst", data.access_token);
                localStorage.setItem("bp", JSON.stringify(data.user.permission));
                // if (data.user.permission.frontPermission.hasOwnProperty("alert")) {
                if (Object.prototype.hasOwnProperty.call(data.user.permission.frontPermission, "alert")) {
                    localStorage.setItem("alertChannel", JSON.stringify(data.user.permission.frontPermission.alert.channel));
                } else {
                    localStorage.setItem("alertChannel", JSON.stringify([]));
                }
                localStorage.setItem("alertHide", JSON.stringify(data.alert_book_hide));
                localStorage.setItem("alertHideBroker", JSON.stringify(data.alert_book_hide_broker));
                localStorage.setItem("alertNotificationSettings", JSON.stringify(data.alert_notification_rule));
                localStorage.setItem("globalNotificationAlert", "true");

                // const brokerSymbolRule = data.symbol_rule.map(item => item.endfix);
                localStorage.setItem("brokerSymbolRule", JSON.stringify(data.symbol_rule));
                localStorage.setItem("bm", JSON.stringify(data.book_map));
                
                data.layout_alert = self.checkHeaders(data.layout_alert);

                // // check if alert header missing in ajax data
                // for (let alert in data.layout_alert.alertHeader) {
                //     if (Object.prototype.hasOwnProperty.call(this.$store.state.alertHeader, alert)) {

                //         let alertHeaderStore = this.$store.state.alertHeader[alert]
                //         let alertHeaderAjax = alertHeaderAjax = data.layout_alert.alertHeader[alert]
                        
                //         for (let a = 0; a < alertHeaderStore.length; a++) {
                //             let find = false;

                //             for (let i = 0; i < alertHeaderAjax.length; i++) {
                //                 if (alertHeaderStore[a].value === alertHeaderAjax[i].value) {
                //                     find = true;
                //                     break;
                //                 }
                //             }

                //             if (!find) {
                //                 data.layout_alert.alertHeader[alert].push(alertHeaderStore[a]);
                //             }
                //         }
                //     }
                // }

                localStorage.setItem("layoutAlert", JSON.stringify(data.layout_alert));

                // console.log("Access Herer");
                // console.log(self.redirectUrl);

                setTimeout(() => {
                    if (self.redirectUrl !== undefined) {
                        self.$router.push({ name: "Home" });
                        this.$store.state.loginStatus = undefined;
                        self.loading = false;
                        // this.btnLoading = false
                        self.btnLoadingCodeValidate = false;
                        this.$store.commit("alertSubscribe");
                    }
                }, 1000);
            });
        },
        /**
         * Switch login method
         * @param   {[type]}  type  [type description]
         * @return  {[type]}        [return description]
         */
        changeLogin (type) {
            this.showLoginEmail = false;
            this.showLoginMobile = false;
            if (type === 1) this.showLoginEmail = true;
            if (type === 2) this.showLoginMobile = true;
        },
        /**
         * [redirectForgetPassword description]
         * @return {[type]} [description]
         */
        redirectForgetPassword () {
            this.$router.push({ name: "ForgetPassword" });
        },
        /**
         * Handle user login
         * @return  {[type]}  [return description]
         */
        login () {
            const self = this;

            const params = { email: self.email, password: self.password };
            console.log(params);
            this.$store.state.jwtToken = "";

            this.btnLoading = true;
            this.loading = true;

            apiAuth.login(params).then(res => {
                const data = res.data;
                console.log(data);

                self.snackBarSuccess(res.data.message);

                self.$store.state.jwtToken = data.access_token;
                self.$store.state.assignUser = data.user;
                // console.log(data.user.permission.frontPermission);

                localStorage.setItem("displayName", data.user.first_name + " " + data.user.last_name);
                localStorage.setItem("bst", data.access_token);
                localStorage.setItem("bp", JSON.stringify(data.user.permission));
                // if (data.user.permission.frontPermission.hasOwnProperty("alert")) {
                if (Object.prototype.hasOwnProperty.call(data.user.permission.frontPermission, "alert")) {
                    localStorage.setItem("alertChannel", JSON.stringify(data.user.permission.frontPermission.alert.channel));
                } else {
                    localStorage.setItem("alertChannel", JSON.stringify([]));
                }
                localStorage.setItem("alertHide", JSON.stringify(data.alert_book_hide));
                localStorage.setItem("alertHideBroker", JSON.stringify(data.alert_book_hide_broker));
                localStorage.setItem("alertNotificationSettings", JSON.stringify(data.alert_notification_rule));
                localStorage.setItem("globalNotificationAlert", "true");

                // const brokerSymbolRule = data.symbol_rule.map(item => item.endfix);
                localStorage.setItem("brokerSymbolRule", JSON.stringify(data.symbol_rule));
                localStorage.setItem("bm", JSON.stringify(data.book_map));
                
                data.layout_alert = self.checkHeaders(data.layout_alert);

                localStorage.setItem("layoutAlert", JSON.stringify(data.layout_alert));

                setTimeout(() => {
                    if (self.redirectUrl !== undefined) {
                        self.$router.push({ name: "Home" });
                        this.$store.state.loginStatus = undefined;
                        this.loading = false;
                        this.btnLoading = false;
                        this.$store.commit("alertSubscribe");
                    }
                }, 1000);
            }).catch(error => {
                this.loading = false;
                this.btnLoading = false;
                console.log(error);
            });
        }
    },
    created () {},
    mounted () {},
    destroyed () {}
};
</script>
